<template>
        <!-- <index-header></index-header> -->
        <router-view name="header"></router-view>
        <router-view></router-view>
        <!-- <index-footer></index-footer> -->
</template>

<script>
// import IndexFooter from '@/components/IndexFooter.vue'
import Component from '@/assets/js/components.js'
export default {
  components: Component.components,
  name: 'Index'
}
</script>

<style scoped>

</style>
